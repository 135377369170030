<template>
  <div>
    <a-form-model
      ref="assignListForm"
      :model="assignListForm"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 18 }"
    >
      <div v-for="(assign, index) in assignListForm.assigns" :key="assign.key">
        <a-divider>技能{{ index + 1 }}<a-icon type="caret-down" />          <a-icon
            class="dynamic-delete-button"
            type="minus-circle-o"
            @click="removeAssigns(assign)"
          /></a-divider>
        <a-form-model-item
          label="技能类型"
          :prop="'assigns.' + index + '.skillType'"
          :rules="{
            required: true, 
            message: '请选择技能类型',
            trigger: 'blur',
          }"
        >
          <a-select
            show-search
            placeholder="选择类型"
            v-model="assign.skillType"
            style="width: 100%"
          >
            <a-select-option
              v-for="s in skillType"
              :key="s.keyy"
              :value="s.keyy"
              >{{ s.valuee }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="技能"
          :prop="'assigns.' + index + '.skillId'"
          :rules="{
            required: true, 
            message: '请选择技能',
            trigger: 'blur',
          }"
        >
          <skill-select v-model="assign.skillId"></skill-select>
        </a-form-model-item>
        <a-form-model-item
          label="掌握程度"
          :prop="'assigns.' + index + '.degree'"
          :rules="{
            required: true, 
            message: '请选择掌握程度',
            trigger: 'blur',
          }"
        >
          <a-select
            show-search
            placeholder="选择类型"
            v-model="assign.degree"
            style="width: 100%"
          >
            <a-select-option
              v-for="s in degree"
              :key="s.keyy"
              :value="s.keyy"
              >{{ s.valuee }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="认证日期"
          :prop="'assigns.' + index + '.authorizedDate'"
          :rules="{
            required: true, 
            message: '请选择认证日期',
            trigger: 'blur',
          }"
        >
        <a-date-picker
          v-model="assign.authorizedDate"
          format="YYYY-MM-DD"
          @panelChange="assignPanelChange"
          @change="assignChange"
          @openChange="pickerOpen(index)"
        />
        </a-form-model-item>
      </div>
    </a-form-model>

    <div v-if="needAdd">
      <a-button type="primary" @click="addAssigns()"> 新增技能 </a-button>
    </div>
  </div>
</template>
<script>
import SkillSelect from "../business-common/SkillSelect";
import moment from "moment";

export default {
  name: "skillAssignRecord",
  components: {SkillSelect},
  props: {
    handleType: Number,
    needAdd: {
      type: Boolean,
      default: true
    },
    skillType: {
      type: Array,
      required: true,
      default: () => [],
    },
    degree: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      currentCourse: 0,
      assignListForm: {
        assigns: [
          {
          id: undefined,
          skillType: undefined,
          skillId: undefined,
          degree: undefined,
          key: Date.now(),
          authorizedDate: moment('2021-06-30'),
          userId: undefined,
          }
        ],
      },
    };
  },
  methods: {
    moment,
    removeAssigns(item) {
      if (this.assignListForm.assigns.length <= 1) {
        this.$notification.warning({
          message: "警告提示",
          description: "至少保留一项技能",
        });
        return;
      }

      let index = this.assignListForm.assigns.indexOf(item);
      if (index !== -1) {
        this.assignListForm.assigns.splice(index, 1);
      }
    },
    addAssigns() {
      this.assignListForm.assigns.push({
        id: undefined,
        skillType: undefined,
        skillId: undefined,
        degree: undefined,
        key: Date.now(),
        authorizedDate: moment('2021-06-30'),
        userId: undefined,
      });
    },

    pickerOpen(index) {
      this.currentCourse = index;
      console.log(index);
    },

    setAssignListForm(assignArray) {
      this.assignListForm.assigns = assignArray;
    },
    assignPanelChange(value, mode) {
      console.log("cpg", value);
      console.log("this.assignListForm", this.assignListForm);
      this.assignListForm.assigns[this.currentCourse].authorizedDate = moment(value)
    },
    assignChange(value) {
      console.log("cpg", value);
      console.log("this.assignListForm", this.assignListForm);
      this.assignListForm.assigns[this.currentCourse].authorizedDate = moment(value)
    },
    reset() {
      this.$refs.assignListForm.resetFields();
      this.assignListForm.assigns = [
        {
          id: undefined,
          skillType: undefined,
          skillId: undefined,
          degree: undefined,
          key: Date.now(),
          authorizedDate: undefined,
          userId: undefined,
        },
      ];
    },

    validateForm() {
      let p = new Promise((resolve,reject) => {
        this.$refs.assignListForm.validate((valid) => {
          if (valid) {
            resolve(valid);
          } else {
            reject(valid);
          }
        });
      });
      return p;
    },

    getFormInfo() {
      let _this = this;
      let assigns = JSON.parse(JSON.stringify(_this.assignListForm.assigns));
      assigns.forEach((ele, index, arr) => {
        arr[index].authorizedDate = moment(arr[index].authorizedDate).valueOf();
        delete arr[index].key;
        delete arr[index].timeFrame;
      });
      return assigns
    },
  },
};
</script>