<template>
  <a-drawer
    title="技能分配"
    :maskClosable="false"
    width="650"
    placement="right"
    @close="handleClose()"
    :visible="drawerShow"
    :closable="false"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :validateOnRuleChange="true"
        :model="form"
        :rules="formRules"
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label='员工' prop="userId" >
          <hourly-user-select
            v-model="form.userId"
            :defaultUserList="defaultUserList"
            placeholder="选择人员"
            :disabled="handleType!=0"
          >
          </hourly-user-select>
        </a-form-model-item>
      </a-form-model>
        <skill-assign-record
        ref="skillAssignRecord"
        :skillType="this.skillType"
        :degree="this.degree"
        ></skill-assign-record>
    </div>

    <div class="drawer-bootom-button">
			<a-button @click="handleClose()" style="margin-right: 0.8rem">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :loading="loading">{{
        handleType === 0 ? "添加" : "编辑"
      }}</a-button>
    </div>
  </a-drawer>
</template>
<script>
import moment from "moment";
import HourlyUserSelect from "../business-common/HourlyUserSelect";
import SkillAssignRecord from "./SkillAssignRecord";

const formRules = {
  userId: [{ required: true, message: "请选择员工", trigger: "blur" }],
};
export default {
  name: "skillAssignDetail",
  components: {HourlyUserSelect, SkillAssignRecord},
  data() {
    return {
      form: {
        userId: undefined,
      },
      defaultUserList: [],
      loading: false,
      formRules,
      handleType: 0,
      drawerShow: false,
    };
  },
  props: {
    skillLevel: {
      type: Array,
      required: true,
      default: () => [],
    },
    skillType: {
      type: Array,
      required: true,
      default: () => [],
    },
    degree: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
    },
    handleClose() {
      this.drawerShow = false;
      this.loading = false;
      this.$refs.skillAssignRecord.reset();
      this.reset();
    },
    setForm(handleType, data) {
      this.handleType = handleType;
      if (!handleType) {
        for (let key in this.form) {
          this.form[key] = undefined;
        }
      }else{
        this.form.userId = data.userId;
        this.defaultUserList = [{userId: data.userId, username: data.username}]
        const assignArray = data.skillAssignList.map(item => {return {
          id: item.id, 
          skillType: item.skillType, 
          skillId: item.skillId, 
          degree: item.degree , 
          authorizedDate: moment(item.authorizedDate),
          userId: item.userId,
          key: Date.now() + item.id + '',
          }})
        this.$nextTick(()=>{
            if (this.$refs.skillAssignRecord)
              this.$refs.skillAssignRecord.setAssignListForm(assignArray);
        })
      }
      this.drawerShow = true;
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
        this.$refs.skillAssignRecord
        .validateForm()
        .then((v) => {
            this.loading = true;
            let courses = this.$refs.skillAssignRecord.getFormInfo();
            courses.map(item=>{item.userId = this.form.userId})
            //新增操作
            if (this.handleType === 0) {
              this.$post("skill-assign", courses).then((r) => {
                this.$message.success(r.data.message);
                this.$emit("success");
                this.handleClose();
                console.log("success", r);
              });
            } else if (this.handleType === 1) {
                this.$put("skill-assign", courses).then((r) => {
                this.$message.success(r.data.message);
                this.handleClose();
                this.$emit("success");
                console.log(r);
              });
            }
        })
        .catch((e) => {
          console.log(e);
        });
        }
      });
      console.log("submit");
    },
  },
};
</script>