<template>
  <a-select
    :mode="mode"
    show-search
    placeholder="选择技能"
    option-filter-prop="children"
    style="width: 100%"
    :value="skillId"
    @change="handleChange"
    :filter-option="filterOption"
  >
    <a-select-option v-for="s in skillData" :key="s.skillId" :value="s.skillId">{{s.title}}-{{s.level}}级-{{s.deptName}}</a-select-option>
  </a-select>
</template>
<script>
export default {
  name: 'SkillSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Number
      // 这个参数是v-decorator给子组件传值用的
      // 这里不要给默认值， 在form下使用会爆警告 Warning: SquareUpload `default value` can not collect,  please use `option.initialValue` to set default value.
    },
    mode: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      skillId: undefined,
      skillData: []
    }
  },
  mounted () {
    this.$get('skill/list').then((r) => {
      this.skillData = r.data.data
    })
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange (value) {
      this.skillId = value
    }
  },
  watch: {
    // 监听数据变化，及时提交给父组件
    skillId: {
      deep: true,
      immediate: true,
      handler: function (newV, oldV) {
        // 向父组件更新
        this.$emit('change', newV)
      }
    },
    // 监听父组件传递过来的数据
    value: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.skillId = newV
      }
    }
  }
};
</script>
