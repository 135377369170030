var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-model',{ref:"assignListForm",attrs:{"model":_vm.assignListForm,"label-col":{ span: 3 },"wrapper-col":{ span: 18 }}},_vm._l((_vm.assignListForm.assigns),function(assign,index){return _c('div',{key:assign.key},[_c('a-divider',[_vm._v("技能"+_vm._s(index + 1)),_c('a-icon',{attrs:{"type":"caret-down"}}),_vm._v(" "),_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"minus-circle-o"},on:{"click":function($event){return _vm.removeAssigns(assign)}}})],1),_c('a-form-model-item',{attrs:{"label":"技能类型","prop":'assigns.' + index + '.skillType',"rules":{
          required: true, 
          message: '请选择技能类型',
          trigger: 'blur',
        }}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"show-search":"","placeholder":"选择类型"},model:{value:(assign.skillType),callback:function ($$v) {_vm.$set(assign, "skillType", $$v)},expression:"assign.skillType"}},_vm._l((_vm.skillType),function(s){return _c('a-select-option',{key:s.keyy,attrs:{"value":s.keyy}},[_vm._v(_vm._s(s.valuee))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"技能","prop":'assigns.' + index + '.skillId',"rules":{
          required: true, 
          message: '请选择技能',
          trigger: 'blur',
        }}},[_c('skill-select',{model:{value:(assign.skillId),callback:function ($$v) {_vm.$set(assign, "skillId", $$v)},expression:"assign.skillId"}})],1),_c('a-form-model-item',{attrs:{"label":"掌握程度","prop":'assigns.' + index + '.degree',"rules":{
          required: true, 
          message: '请选择掌握程度',
          trigger: 'blur',
        }}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"show-search":"","placeholder":"选择类型"},model:{value:(assign.degree),callback:function ($$v) {_vm.$set(assign, "degree", $$v)},expression:"assign.degree"}},_vm._l((_vm.degree),function(s){return _c('a-select-option',{key:s.keyy,attrs:{"value":s.keyy}},[_vm._v(_vm._s(s.valuee))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"认证日期","prop":'assigns.' + index + '.authorizedDate',"rules":{
          required: true, 
          message: '请选择认证日期',
          trigger: 'blur',
        }}},[_c('a-date-picker',{attrs:{"format":"YYYY-MM-DD"},on:{"panelChange":_vm.assignPanelChange,"change":_vm.assignChange,"openChange":function($event){return _vm.pickerOpen(index)}},model:{value:(assign.authorizedDate),callback:function ($$v) {_vm.$set(assign, "authorizedDate", $$v)},expression:"assign.authorizedDate"}})],1)],1)}),0),(_vm.needAdd)?_c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addAssigns()}}},[_vm._v(" 新增技能 ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }