<template>
  <a-select
    show-search
    placeholder="输入员工姓名"
    option-filter-prop="children"
    style="width: 100%"
    :value="userId"
    @search="fetchUserName"
    @change="onUserNameChange"
    :show-arrow="false"
    :disabled="disabled"
  >
    <a-select-option
      v-for="p in userList"
      :key="p.userId"
      :value="p.userId"
      >{{ p.username }}</a-select-option
    >
  </a-select>
</template>
<script>
import debounce from "lodash/debounce";
export default {
  name: "HourlyUserSelect",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      String: Number,
      // 这个参数是v-decorator给子组件传值用的
      // 这里不要给默认值， 在form下使用会爆警告 Warning: SquareUpload `default value` can not collect,  please use `option.initialValue` to set default value.
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultUserList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    this.lastFetchId = 0; // 只取最后一次结果
    this.fetchUserName = debounce(this.doSearchUserList, 800);
    return {
      fetching: false, // 查询时的loading
      userId: undefined,
      userList: [],
    };
  },
  mounted() {
    this.userList = this.defaultUserList
  },
  methods: {
    onUserNameChange(value) {
      this.userId = value;
    },
    doSearchUserList(value) {
      this.userList = [];
      this.fetching = true;
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      if (value == "") {
        return;
      }
      this.$get('user/simple-list', {userType: '2', username: value})
        .then((r) => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          this.fetching = false;
          this.userList = r.data || [];
        })
        .catch(() => {
          this.userList = [];
          this.fetching = false;
        });
    },
  },
  watch: {
    // 监听数据变化，及时提交给父组件
    userId: {
      deep: true,
      immediate: true,
      handler: function (newV, oldV) {
        // 向父组件更新
        this.$emit("change", newV);
      },
    },
    // 监听父组件传递过来的数据
    value: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.userId = newV;
      },
    },
    // 监听父组件传递过来的数据
    defaultUserList: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.userList = newV;
      },
    },
  },
};
</script>
