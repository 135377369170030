<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 12 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item label="部门">
              <a-tree-select
                v-model="queryParams.deptId"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="deptTreeData"
                placeholder="选择部门"
                @change="handleAcceptDeptChange"
                tree-default-expand-all
              >
              </a-tree-select>
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item
              label="员工"
              :labelCol="{ span: 7 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <hourly-user-select
                v-model="queryParams.userId"
                placeholder="选择人员"
              >
              </hourly-user-select>
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item
              label="技能类型"
              :labelCol="{ span: 7 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择类型"
                v-model="queryParams.skillType"
                style="width: 100%"
              >
                <a-select-option
                  v-for="s in skillType"
                  :key="s.keyy"
                  :value="s.keyy"
                  >{{ s.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item
              label="掌握程度"
              :labelCol="{ span: 7 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择掌握程度"
                v-model="queryParams.degree"
                style="width: 100%"
              >
                <a-select-option
                  v-for="s in degree"
                  :key="s.keyy"
                  :value="s.keyy"
                  >{{ s.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="operator">
      <a-button type="primary" @click="clickAdd()">分配</a-button>
    </div>
    <div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.userId"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <template slot="operation" slot-scope="text, record">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <a-icon
                type="setting"
                theme="twoTone"
                twoToneColor="#4a9ff5"
              ></a-icon>
            </a>
            <a-menu slot="overlay">
              <a-menu-item key="0">
                <a target="_blank" @click="clickEdit(record)">编辑</a>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item
                key="3"
                @click="clickDel(record)"
              >
                删除
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
        <a-table
          slot="expandedRowRender"
          slot-scope="text"
          :columns="innerColumns"
          :data-source="text.skillAssignList"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
          <span slot="innerOperation" slot-scope="text" class="table-operation">
            <a-icon type="setting" theme="twoTone" twoToneColor="#4a9ff5" title="修改技能"></a-icon>
            <a-badge status="warning" text="无权限"></a-badge>
          </span>
        </a-table>
      </a-table>
      <skill-assign-detail
        :skillLevel="this.skillLevel"
        :skillType="this.skillType"
        :degree="this.degree"
        ref="skillAssignDetail"
        @success="handleSuccess"
      >
      </skill-assign-detail>
    </div>
  </a-card>
</template>
<script>
import {getDeptRootId} from './../../utils/tools'
import SkillAssignDetail from "@/views/training/SkillAssignDetail";
import HourlyUserSelect from "../business-common/HourlyUserSelect";
import { mapState } from "vuex";

export default {
  name: "skillAssign",
  components: { SkillAssignDetail, HourlyUserSelect },
  data() {
    return {
      deptTreeData: [],
      dataSource: [],
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
      selectedRowKeys: [],
      handleType: 0,
      type: {},
      method: {},
      appraise: {},
      queryParams: {
        deptId: undefined,
        level: undefined,
        title: undefined
      },
      drawerShow: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      skillLevel: (state) => state.dict.dicts.skill_level,
      skillType: (state) => state.dict.dicts.skill_assign_skill_type,
      degree: (state) => state.dict.dicts.skill_assign_degree,
    }),
    topDeptId() {
      return getDeptRootId('inner')
    },
    columns() {
      return [
        {
          title: "员工",
          width: 100,
          dataIndex: "username",
        },
        {
          title: "部门",
          width: 200,
          dataIndex: "deptName",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: 100,
        },
      ];
    },
  innerColumns() {
      return [
        {
          title: "技能类型",
          width: 100,
          dataIndex: "skillType",
          customRender: (text, row, index) => {
            for (let _index in this.skillType) {
              let key = this.skillType[_index].keyy;
              if (text === key) {
                let val = this.skillType[_index].valuee;
                let color = this.skillType[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
        },
        {
          title: "技能名称",
          width: 200,
          dataIndex: "skillTitle",
        },
        {
          title: "技能等级",
          width: 100,
          dataIndex: "skillLevel",
          customRender: (text, row, index) => {
            for (let _index in this.skillLevel) {
              let key = this.skillLevel[_index].keyy;
              if (text === key) {
                let val = this.skillLevel[_index].valuee;
                let color = this.skillLevel[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
        },
        {
          title: "掌握程度",
          width: 100,
          dataIndex: "degree",
          customRender: (text, row, index) => {
            for (let _index in this.degree) {
              let key = this.degree[_index].keyy;
              if (text === key) {
                let val = this.degree[_index].valuee;
                let color = this.degree[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
        },
        {
          title: "认证日期",
          width: 200,
          dataIndex: "authorizedDate",
        },
        // {
        //   title: "操作",
        //   dataIndex: "innerOperation",
        //   scopedSlots: { customRender: "innerOperation" },
        //   width: 100,
        // },
      ];    
  },
  },
  mounted() {
    this.$get("dept", { rootDeptIds: this.topDeptId }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
    this.fetch();
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    reset() {
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      if (this.paginationInfo) {
        this.paginationInfo.current = this.pagination.defaultCurrent;
        this.paginationInfo.pageSize = this.pagination.defaultPageSize;
      }
      this.paginationInfo = null;
      // 取消选中
      this.selectedRowKeys = [];

      // 重置查询参数
      this.queryParams = { trainingMethod: 1 };
      this.fetch(this.queryParams);
    },
    handleTableChange(pagination, filters, sorter) {
      this.paginationInfo = pagination;
      this.fetch({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      this.$get("skill-assign", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    clickAdd() {
      this.$refs.skillAssignDetail.setForm(0);
    },
    clickEdit(record) {
      this.$refs.skillAssignDetail.setForm(1, record);
    },
    clickDel(record) {
      let that = this;
      this.$confirm({
        title: "确定删除所当前记录吗？",
        content: "当您点击确定按钮后，这些记录将会被彻底删除",
        centered: true,
        onOk () {
          that.$delete(`skill-assign/${record.userId}`)
            .then(() => {
              that.$message.success("删除成功！");
              that.fetch();
            })
            .catch(e => console.log("删除失败！==> ", e));
        },
        onCancel () {
          
        }
      })
    },
    handleAcceptDeptChange(value, label) {
      console.log("deptId ==>", value, label[0]);
      this.queryParams.deptId = +value;
    },
    handleSuccess() {
      this.fetch(this.queryParams);
    },
    search() {
      this.fetch(this.queryParams);
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../utils/Common.less";
</style>